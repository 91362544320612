#footer {
  padding-top: 40px;
  font-size: 1.7rem;
  background: #ffffff;
  color: black;
  position: relative;
  bottom: 0;
}

@media (max-width:479px) {
  #footer {
    bottom: 17px;
  }
}

#footer h5 {
  border-bottom: 1px solid #77757521;
  padding-bottom: 6px;
  margin-bottom: 20px;
  color: black;
  font-size: 2.3rem;
  font-weight: 600;

}

#footer a,
#footer button.link {
  color: black;
  text-decoration: none !important;

  background-color: transparent;
  text-align: start;
  -webkit-text-decoration-skip: objects;
  font-weight: 600;
  display: flex;
}

#footer a:hover,
#footer button.link:hover {
  color: var(--secondary-color);
}

#footer ul {
  list-style-type: disc;
  padding: 0;
}

#footer ul li {
  margin-bottom: 10px;
  overflow: hidden;
}

#footer ul li::marker {
  color: var(--secondary-color);
}

#footer .download-button {
  height: 40px;
  background: inherit;
}

#footer .download-button img {
  width: 100%;
  height: 100%;
}

#footer .bottom-section-footer {
  display: flex;
  justify-content: space-between;
  color: var(--text-field-color);
  ;
  align-items: center;
  padding: 8px 0;
}

#footer .copyright p {
  margin: auto;
  color: var(--text-field-color);
  ;

}

@media (max-width: 48rem) {
  #footer {
    margin-bottom: 35px;
  }
}


#footer .copyright .company_name {
  font-weight: 800;
}

#footer a.socical-icons {
  padding: 10px 5px;
  border: 1px solid;
  border-radius: 5px;
  margin: 0 2px;
}

#footer a.socical-icons i {
  padding: 5px;
}

#footer .social-media-icons span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 700;
}

#footer .payment_methods_container .payment_methods {
  display: inline-flex;
  border-radius: 5px;
  width: 50px;
  background: var(--text-field-color);
  ;
  margin: 5px;
  padding: 8px
}

#footer .payment_methods_container .payment_methods img {
  width: 100%;
}

#footer .download_desc p {
  color: var(--text-field-color);
  ;
  font-size: 16px;
  font-weight: 600;
}

#footer .app-title {

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}